// BlogPostPage.tsx

import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BlogContext, contentfulClient } from '../App';
import { BlogPosts } from '../components/Resources/Data';

// Styled Components

// Scroll Progress Bar
const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 5px; /* Height of the progress bar */
  background-color: #ff0000; /* Color of the progress bar */
  z-index: 9999;
  transition: width 0.2s ease; /* Transition for smooth width changes */
`;

// Blog Content Section
const BlogSection = styled.section`
  max-width: 800px;
  padding: 2rem 1rem;
  margin: 2rem auto;
`;

// Blog Title
const BlogTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  margin: 1rem 0;
  text-align: center;
  color: #333;
`;

// Blog Body
const BlogBody = styled.div`
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1rem;
  text-align: justify;
  color: #555;
`;

// Optional: Container for additional styling or future enhancements
const Container = styled.div`
  padding: 0 1rem;
`;

// Main Component
const BlogPostPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const blogPosts = useContext(BlogContext);
  const location = useLocation();
  const [currentBlog, setCurrentBlog] = useState<BlogPosts | null>(null);
  const [assets, setAssets] = useState<{ [id: string]: string }>({});

  // Scroll Progress Handler
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight; // Accurate document height

    const progress = (scrollY / (documentHeight - windowHeight)) * 100;
    setScrollProgress(progress);
  };

  // Attach Scroll Event Listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Fetch Assets (Images) for Blog Posts
  useEffect(() => {
    blogPosts.forEach((post) => {
      contentfulClient
        .getAsset(post.imageURL)
        .then((asset) =>
          setAssets((prevAssets) => ({ ...prevAssets, [post.id]: asset.fields.file.url }))
        )
        .catch((error) => console.error('Error fetching asset:', error));
    });
  }, [blogPosts]);

  // Determine the Current Blog Post Based on URL
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const foundBlog = blogPosts.find((post) => post.id === lastSegment);
    setCurrentBlog(foundBlog || null);
  }, [location.pathname, blogPosts]);

  return (
    <div>
      {/* Scroll Progress Bar */}
      <ProgressBar style={{ width: `${scrollProgress}%` }} />

      {/* Blog Content */}
      <Container>
        {currentBlog && (
          <BlogSection>
            <BlogTitle>{currentBlog.title}</BlogTitle>
            <BlogBody>{currentBlog.body}</BlogBody>
          </BlogSection>
        )}
      </Container>
    </div>
  );
};

export default BlogPostPage;
