import { nanoid } from 'nanoid'
import {
  AKEEL,
  ALETSON,
  ATRUFELLMAN,
  BENTZMINGER,
  BGIAEVER,
  CBANNON,
  CDMONTEBELLO,
  CKRIMITSOS,
  CVINCENT,
  EBOITEIN,
  HLEIVA,
  IGLASS,
  JBRUDER,
  JDELORE,
  JSESSLER,
  JSUBRIN,
  LANWAR,
  LOO,
  MLOBEL,
  NQUAH,
  OEISENBERG,
  PSDOLMAN,
  RWALCH,
  SNEWMAN,
  SRUST,
  TKAY,
  TKS,
  VGELLER,
  VLANG,
  VWILLIAMS,
  WDORR,
  WPEARSON,
  ZSAUNDERS,
  MTAN,
  EEDDINGS,
  LABRAMS,
  KBISHOP,
  KGIVEN,
  NHILL,
  ICOSS,
  LDAY,
  MMCCUNE,
  BRISKIN,
  DBOULANGE,
  NDELTORO,
  LPASSELL,
  DCHIVVIS,
  JGONZALES,
} from '../../assets/guests'

export const GUESTS_DATA = [
  {
    id: nanoid(),
    name: 'Al Letson',
    title: 'Host of Reveal (PRX)',
    image: ALETSON,
  },
  {
    id: nanoid(),
    name: 'Latinos Out Loud',
    title: 'Hosts of Latinos Out Loud',
    image: LOO,
  },
  {
    id: nanoid(),
    name: 'Veralyn Williams',
    title: 'EP, The United States of Anxiety (WNYC)',
    image: VWILLIAMS,
  },
  {
    id: nanoid(),
    name: 'Ira Glass',
    title: 'Executive Producer & Host, This American Life',
    image: IGLASS,
  },
  {
    id: nanoid(),
    name: 'Nick Quah',
    title: 'Founder & Editor, Hot Pod Newsletter',
    image: NQUAH,
  },
  {
    id: nanoid(),
    name: 'Avery Trufelman',
    title: 'Host of The Cut and Nice Try! (Vox Media)',
    image: ATRUFELLMAN,
  },
  {
    id: nanoid(),
    name: 'Chris Bannon',
    title: 'Chief Content Officer, Stitcher',
    image: CBANNON,
  },
  {
    id: nanoid(),
    name: 'Nikki Silva',
    title: 'Co-Executive Producer, The Kitchen Sisters',
    image: TKS,
  },
  {
    id: nanoid(),
    name: 'Chris Krimitsos',
    title: 'Founder/Chief Creative Officer, PodFest',
    image: CKRIMITSOS,
  },
  {
    id: nanoid(),
    name: 'Zoe Saunders',
    title: 'Senior Producer, Original Series, CNN Audio',
    image: ZSAUNDERS,
  },
  {
    id: nanoid(),
    name: 'Ophira Eisenberg',
    title: 'Host of Ask Me Another (NPR)',
    image: OEISENBERG,
  },
  {
    id: nanoid(),
    name: 'Emily Botein',
    title: 'VP, Original Programming, WNYC Studios',
    image: EBOITEIN,
  },
  {
    id: nanoid(),
    name: 'Julie Subrin',
    title: 'Independent Producer & Editor',
    image: JSUBRIN,
  },
  {
    id: nanoid(),
    name: 'Wendy Dorr',
    title: 'Executive Producer, New York Times Audio',
    image: WDORR,
  },
  {
    id: nanoid(),
    name: 'Mia Lobel',
    title: 'Executive Producer, Pushkin Industries',
    image: MLOBEL,
  },
  {
    id: nanoid(),
    name: 'Abigail Keel',
    title: 'Senior Producer, Stitcher',
    image: AKEEL,
  },
  {
    id: nanoid(),
    name: 'Liyna Anwar',
    title: 'Producer, StoryCorps',
    image: LANWAR,
  },
  {
    id: nanoid(),
    name: 'John Delore',
    title: 'Senior Editor, Stitcher',
    image: JDELORE,
  },
  {
    id: nanoid(),
    name: 'Valerie Geller',
    title: 'Consultant, Geller Media International',
    image: VGELLER,
  },
  {
    id: nanoid(),
    name: 'Scott Newman',
    title: 'Founder & Creative Director, On Air Fest',
    image: SNEWMAN,
  },
  {
    id: nanoid(),
    name: 'Charles De Montebello',
    title: 'Owner & Engineer, CDM Sound Studios',
    image: CDMONTEBELLO,
  },
  {
    id: nanoid(),
    name: 'Trey Kay',
    title: 'Host & Producer, Us & Them',
    image: TKAY,
  },
  {
    id: nanoid(),
    name: 'Victoria Lang',
    title: 'Producer, Found',
    image: VLANG,
  },
  {
    id: nanoid(),
    name: 'Jared Bruder',
    title: 'Director of Brand Marketing, The Dandy Group',
    image: JBRUDER,
  },
  {
    id: nanoid(),
    name: 'Joshua Sessler',
    title: 'Law Partner, Cowan DeBaets Abrahams & Sheppard',
    image: JSESSLER,
  },
  {
    id: nanoid(),
    name: 'Hendel Leiva',
    title: 'Host & Producer, Immigration MIC Podcast',
    image: HLEIVA,
  },
  {
    id: nanoid(),
    name: 'Rob Walch',
    title: 'VP, Podcaster Relations Libsyn',
    image: RWALCH,
  },
  {
    id: nanoid(),
    name: 'Bryan Entzminger',
    title: 'Engineer/Editor, Top Tier Audio',
    image: BENTZMINGER,
  },
  {
    id: nanoid(),
    name: 'Bianca Giaever',
    title: 'Producer, New York Times Audio',
    image: BGIAEVER,
  },
  {
    id: nanoid(),
    name: 'Cher Vincent',
    title: 'Senior Producer, Pushkin Industries',
    image: CVINCENT,
  },
  {
    id: nanoid(),
    name: 'Paul Samuel Dolman',
    title: 'Host of What Matters Most',
    image: PSDOLMAN,
  },
  {
    id: nanoid(),
    name: 'Suzanne Rust',
    title: 'Senior Curatorial Producer, The Moth',
    image: SRUST,
  },
  {
    id: nanoid(),
    name: 'Will Pearson',
    title: 'Chief Operations Officer, iHeart Podcast Network',
    image: WPEARSON,
  },
  {
    id: nanoid(),
    name: 'Megan Tan',
    title: 'Senior Producer, LAist Studios',
    image: MTAN,
  },
  {
    id: nanoid(),
    name: 'Eric Eddings',
    title: 'Director, Stitcher, For Colored Nerds',
    image: EEDDINGS,
  },
  {
    id: nanoid(),
    name: 'Lindsay Abrams',
    title: 'Leading Design Strategist, CNN Podcasts & Audio',
    image: LABRAMS,
  },
  {
    id: nanoid(),
    name: 'Katie Bishop',
    title: 'Executive Producer, Death, Sex & Money',
    image: KBISHOP,
  },
  {
    id: nanoid(),
    name: 'Karen Given',
    title: 'Founder, Narrative Beat',
    image: KGIVEN,
  },
  {
    id: nanoid(),
    name: 'Nichole Hill',
    title: 'Host, Secret Adventures of Black People',
    image: NHILL,
  },
  {
    id: nanoid(),
    name: 'Ian Coss',
    title: 'Host, Forever is a Long Time',
    image: ICOSS,
  },
  {
    id: nanoid(),
    name: 'Leila Day',
    title: 'Host, The Stoop Berry from Podcasts in Color',
    image: LDAY,
  },
  {
    id: nanoid(),
    name: 'Marianne McCune',
    title: 'Reporter, Narrative Unit, The United States of Anxiety',
    image: MMCCUNE,
  },
  {
    id: nanoid(),
    name: 'Benjamin Riskin',
    title: 'Founder, Room Tone',
    image: BRISKIN,
  },
  {
    id: nanoid(),
    name: 'Deb Boulange',
    title: 'CEO, The Great Do-Over',
    image: DBOULANGE,
  },
  {
    id: nanoid(),
    name: 'Natasha Del Toro',
    title: 'Host, Verified',
    image: NDELTORO,
  },
  {
    id: nanoid(),
    name: 'Lauren Passell',
    title: 'Founder, Tink Media',
    image: LPASSELL,
  },
  {
    id: nanoid(),
    name: 'Dana Chivvis',
    title: 'Executive Producer, This American Life / Serial',
    image: DCHIVVIS,
  },
  {
    id: nanoid(),
    name: 'Jocelyn Gonzales',
    title: 'Director, PRX Productions',
    image: JGONZALES,
  },  
]
